<template>
  <div class="wrapper">
    <div class="head_card">
      <p class="nums">开通门店<span class="n">{{enterpriseNum}}</span>家</p>
      <p class="nums_tips ds_flex al_center ju_center" v-if="percent">环比{{percent}}<span class="icon" :class="{icon_up: percent.indexOf('-') == -1, icon_down: percent.indexOf('-') > -1 && percent.indexOf('--') == -1}"></span></p>

    </div>
    <div class="big_title">开通门店清单</div>
    <van-collapse v-model="activeNames" class="coll_wrap">
      <van-collapse-item :name="index + 1 + ''" v-for="(item, index) in enterpriseData" :key="index">
        <template #title>
          <div class="coll_title"><span class="coll_t">{{item.enterpriseName}}</span><span class="coll_conut">{{item.storeCount}}</span>家</div>
        </template>
        <div class="coll_list" v-for="(itemStore, indx) in item.drugStoreList" :key="indx">
          <div class="item ds_flex al_center">
            <div class="num">{{indx + 1}}.</div>
            <div class="name">{{itemStore.drugstoreName}}</div>
            <div class="date">{{itemStore.creatTime}}</div>
          </div>
        </div>
      </van-collapse-item>
    </van-collapse>
  </div>
</template>
<script>
let self = null;
import { getDate, getMonthStartAndEnd, getWeekStartAndEnd } from '@/utils/util';
export default {
  components: {
  },
  data() {
    return {
      activeNames: [],
      time: null,
      enterpriseNum: this.$route.query.enterpriseNum, //门店数量
      percent: this.$route.query.percent, // 环比
      enterpriseData: [],
      userInfo: JSON.parse(localStorage.getItem('userInfo'))
    }
  },
  created() {
    document.title = this.$route.query.title + '-' + this.$route.query.time + '开通药店';
    if (this.$route.query.time == '本周') {
      this.time = getDate('-', '', false);
    } else if (this.$route.query.time == '本月') {
      this.time = getDate('month', '', false);
    } else {
      if (this.$route.query.type == 1) {
        this.time = this.$route.query.weekDate;
      } else {
        this.time = this.$route.query.time;
      }
    }
    this.getEnterpriseData(this.time, this.$route.query.type);
    self = this;
  },
  methods: {
    getEnterpriseData(date, type) {
      let params = {};
      if (type == 1) { //周
        params = {
          beginTime: getWeekStartAndEnd(0, date)[0],
          endTime: getWeekStartAndEnd(0, date)[1]
        }
      } else { //月
        params = {
          month: this.time
        }
      }
      params.agentId = this.userInfo.user.agentId;
      this.$http('get', '/prescription/statistics/getH5EnterpriseStatictis', params).then(res => {
        console.log('统计', res);
        if (res.code == 200) {
          this.enterpriseData = res.data;
          this.enterpriseData.forEach((item, index) => {
            this.activeNames.push(index + 1 + '')
          })
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.ds_flex {
  display: flex;
  display: -webkit-flex;
}
.ju_center {
  justify-content: center;
  -webkit-justify-content: center;
}
.al_center {
  align-items: center;
  -webkit-align-items: center;
}
.wrapper {
  background: #f8f8f8;
  min-height: 100vh;
}
.head_card {
  padding: 0.48rem 0;
  text-align: center;
  border-top: 0.1rem solid #f8f8f8;
  background: #fff;
  .nums {
    margin-bottom: 0.1rem;
    font-size: 0.24rem;
    color: #666666;
    .n {
      margin: 0 0.12rem 0 0.27rem;
      font-size: 0.6rem;
      color: #3780ff;
    }
  }
  .nums_tips {
    font-size: 0.26rem;
    color: #333333;
    .icon {
      display: inline-block;
      margin-left: 0.12rem;
      width: 0.16rem;
      height: 0.23rem;
    }
    .icon_up {
      background: url("../../assets/img/icon_rise_nor@2x.png") no-repeat center /
        100% 100%;
    }
    .icon_down {
      background: url("../../assets/img/icon_reduce_nor@2x.png") no-repeat
        center / 100% 100%;
    }
  }
}
.big_title {
  padding: 0.25rem 0.3rem;
  font-size: 0.32rem;
  font-weight: bold;
  color: #333333;
}
.coll_wrap {
  margin-bottom: 0.15rem;
}
.coll_title {
  font-size: 0.24rem;
  color: #333333;
  .coll_t {
    font-weight: bold;
    font-size: 0.3rem;
  }
  .coll_conut {
    margin-left: 0.22rem;
    font-weight: bold;
    font-size: 0.3rem;
  }
}
.coll_list {
  // margin: 0 .34rem;
  .item {
    padding: 0.2rem 0;
    font-size: 0.28rem;
    color: #333333;
    border-bottom: 1px solid #f2f2f2;
    .num {
      margin-right: 0.33rem;
    }
    .name {
      flex: 1;
      -webkit-flex: 1;
    }
    .date {
      font-size: 0.24rem;
      color: #999999;
    }
  }
  .item:last-child {
    border-bottom: none;
  }
}
</style>
